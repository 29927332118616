@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

$primary: #AFA199;
// $body-background-color: #EDE7E5;
$link: #6C7577;
$text: #4A4440;
$title-color: #4A4440;
$subtitle-color: #4A4440;
$family-primary: 'Raleway', sans-serif;
@import "~bulma";

.hero#home {
  background-image:
    linear-gradient(
      rgba(237, 231, 229, 0.5),
      rgba(237, 231, 229, 0.5)
    ),
    url("https://images.unsplash.com/photo-1483794344563-d27a8d18014e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero-title {
  font-size: 6rem;
}

.avatar {
  height: 100%;
}

.has-background-transparent {
  background-color: transparent;
}
